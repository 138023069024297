import PropTypes from 'prop-types';
import merge from 'lodash/merge';
import ReactApexChart from 'react-apexcharts';
// @mui
import { styled } from '@mui/material/styles';
import { capitalCase } from 'change-case';
import { Avatar, Button, Card, CardHeader, Stack, Typography } from '@mui/material';
// components
import { BaseOptionChart } from '../../../components/chart';

// ----------------------------------------------------------------------

const CHART_HEIGHT = 392;
const LEGEND_HEIGHT = 92;

const ChartWrapperStyle = styled('div')(({ theme }) => ({
  height: "100%",
  marginTop: theme.spacing(2),
  '& .apexcharts-canvas svg': {
    height: CHART_HEIGHT,
  },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible',
  },
  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    alignContent: 'center',
    position: 'relative !important',
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
  },
}));

// ----------------------------------------------------------------------

AppCurrentSubject.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartData: PropTypes.array.isRequired,
  chartColors: PropTypes.arrayOf(PropTypes.string).isRequired,
  chartLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default function AppCurrentSubject({ title, subheader, chartData, ...other }) {


  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />

      <ChartWrapperStyle dir="ltr">
        <Stack p={2} gap={2}>
          {
            chartData.map((item) => (

              <Stack px={2} py={5} direction={"row"} alignItems={"center"} justifyContent={"space-between"} height={"50px"} width={"100%"} boxShadow={"0px 2px 1px 0px rgba(0,0,0,0.09)"} borderRadius={"8px"}>
                <Stack direction={"row"} alignItems={"center"} gap={2}>
                  <Avatar src={item?.profilePic} />
                  <Typography>{capitalCase(item?.fullname)}</Typography>
                </Stack>
                <Typography> <Button >{item?.total}+</Button> Students</Typography>
              </Stack>
            ))
          }
        </Stack>
      </ChartWrapperStyle>
    </Card>
  );
}
