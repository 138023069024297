import { ExpandMore as ExpandMoreIcon, Favorite, MoreVert, Search, Share } from '@mui/icons-material'
import { Avatar, Button, Card, CardActions, CardContent, CardHeader, CardMedia, Collapse, IconButton, InputBase, Pagination, Stack, TextField, Typography, } from '@mui/material'
import { green, red } from '@mui/material/colors'
import { makeStyles, styled } from '@mui/styles';
import React, { useEffect, useState } from 'react'
import * as Yup from "yup"
import { Form, FormikProvider, useFormik } from 'formik';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { capitalCase } from 'change-case';
import { sentenceCase } from "sentence-case"
import moment from 'moment';
import { toast } from 'react-toastify';



const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <Button {...other} />;
})(({ theme, expand }) => ({
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
    color: theme.palette.primary.main
}));

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        // alignItems: 'center',
        background: 'white',
        width: '100%',
        height: 50,
        borderRadius: '12px',
        justifyContent: 'space-between',
        paddingLeft: '15px',
    },
    textField: {
        width: '100%',
        color: 'black',
        '& .MuiInputBase-input': {
            fontSize: '15px',
            color: 'black',
        },
        '& .MuiInputBase-input::placeholder': {
            color: 'black',
        },
    },
    submitButton: {
        width: '22px',
        height: '100%',
        color: 'white',
        background: theme.palette.primary.main,
        borderRadius: '0 12px 12px 0 !important',
    },
    formControl: {
        minWidth: 174,
        height: 40,
        background: '#FFFFFF',
        borderRadius: 8,
        color: 'black',

    },
    iconButton: {
        padding: 8,
    },
    inputBase: {
        fontSize: '15px !important',
        background: 'white',
        borderRadius: 8,
        color: "black !important",
        paddingLeft: 10,
        paddingTop: 10,
    },
    label: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center !important',
        fontSize: '15px !important',
        '&:focus': {
            color: "#242f9b"
        }
    },
    icon: {
        marginRight: theme.spacing(1),
        fontSize: "15px !important"
        // paddingTop: "5px !important",
    },
    menuItem: {
        fontSize: '14px !important',
        color: 'black',
        '&:hover': {
            backgroundColor: '#F2F2F2',
        },
    },
    menuPaper: {
        borderRadius: '8px',
        marginTop: '8px',
        boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)',
    },
    inputLabelRoot: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'black',
        fontSize: '15px',
    },
    inputLabelShrink: {
        transform: 'translate(12px, 10px) scale(0.75)',
        color: 'black',
    },
    paginationContainer: {
        display: 'flex',
        justifyContent: 'center',
        margin: '50px 0 50px 0 ',
    },
    pagination: {
        '& .MuiPaginationItem-root': {
            fontSize: '15px',
            color: 'black',
        },
        '& .Mui-selected': {
            color: 'white',
            backgroundColor: theme.palette.primary.main,
        },
    },
}));

const QnASchema = Yup.object().shape({
    answer: Yup.string().required("Answer is required"),
});

const url = process.env.REACT_APP_API_URL;

function UnitQandA() {

    const classes = useStyles();
    const [searchValue, setSearchValue] = useState("");
    const [questionFilter, setQuestionFilter] = useState("");
    const [qnas, setQnas] = useState([]);

    const [maxRecords, setMaxRecords] = useState(0);
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);
    const [expandedItems, setExpandedItems] = useState({});

    const [answer, setAnswer] = useState("");



    const course = useSelector((state) => state.course.currentCourse);
    const [searchParams, setSearchParams] = useSearchParams();

    const unitId = searchParams.get("unitId");
    const qnaId = searchParams.get("qnaId");

    const handleExpandClick = (itemId) => {
        setExpandedItems((prevExpanded) => ({
            ...prevExpanded,
            [itemId]: !prevExpanded[itemId]
        }));
    };


    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSearchClick();
        }
    };

    const handleSearchClick = () => {
        if (searchValue !== "") {
            setQuestionFilter(searchValue)
        } else {
            setQuestionFilter("")
        }
    }

    const fetchQandAs = async () => {
        try {
            const res = await axios.get(`${url}/qna/admin/getall?qnaId=${qnaId ? qnaId : ""}&courseId=${course._id}&unitId=${unitId}&search=${questionFilter}&page=${page > 0 ? page - 1 : page}&size=${size}`);
            setQnas(res.data.data.results)
            setMaxRecords(Math.ceil(res.data.data.maxRecords / size))
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        fetchQandAs();
    }, [unitId, questionFilter, page, size, qnaId])

    const handleFormSubmit = async (answer, itemId) => {
        try {
            const instructors = course.instructorDocs?.map((instructor) => instructor._id);
            const res = await axios.post(`${url}/qna/answer?id=${itemId}`,
                { answer, unitId, courseId: course._id, instructor: JSON.stringify(instructors) });
            toast.success(res.data.message)
            fetchQandAs();
        } catch (e) {
            toast.error(e.response.data.message)
            console.log(e);
        }
    };

    return (
        <Stack>
            <Stack direction={"row"} >
                <Typography variant='h6'>Q&A</Typography>
            </Stack>

            <Stack>


                <Stack gap={2} mt={4}>
                    <div className={classes.root}>
                        <InputBase
                            placeholder="Search.."
                            className={classes.textField}
                            onChange={(e) => setSearchValue(e.target.value)}
                            onKeyPress={handleKeyPress}
                            value={searchValue}
                        />
                        <Button onClick={() => handleSearchClick()} variant="contained" className={classes.submitButton}>
                            <Search />
                        </Button>
                    </div>
                    {
                        qnas.map((item) => (
                            <Card sx={{ maxWidth: "100%", mt: 3 }}>
                                <CardHeader
                                    avatar={
                                        <Avatar aria-label="recipe" src={item.userData.profilePic}>
                                            A
                                        </Avatar>
                                    }
                                    // action={
                                    //     <IconButton aria-label="settings">
                                    //         <MoreVert />
                                    //     </IconButton>
                                    // }
                                    title={capitalCase(item.userData.fullname)}
                                    subheader={<Typography sx={{ fontSize: "15px !important", color: "#9c9c9c" }}>{moment(item.date).format('LL')}</Typography>}
                                />
                                <CardContent>
                                    <Typography variant="body1" color="text.primary">
                                        {item.question}
                                    </Typography>
                                    {
                                        !item.answer &&

                                        <Stack mt={3} gap={2}>
                                            <TextField multiline
                                                minRows={2}
                                                placeholder='Type answer here..'
                                                value={answer}
                                                onChange={(e) => setAnswer(e.target.value)}
                                            />
                                            <Stack alignItems={"flex-end"} gap={2} direction={"row"}>
                                                <Button variant='outlined' sx={{ color: (theme) => theme.palette.primary.main }}>Cancel</Button>
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    sx={{ background: (theme) => theme.palette.primary.main }}
                                                    onClick={() => handleFormSubmit(answer, item._id)}
                                                >
                                                    Answer
                                                </Button>

                                            </Stack>
                                        </Stack>
                                    }
                                </CardContent>
                                {item.answer &&
                                    <CardActions disableSpacing >
                                        <ExpandMore
                                            expand={expandedItems[item._id]}
                                            onClick={() => handleExpandClick(item._id)}
                                            aria-expanded={expandedItems[item._id]}
                                            aria-label="show more"
                                        >
                                            Replies
                                            <ExpandMoreIcon />
                                        </ExpandMore>
                                    </CardActions>
                                }
                                <Collapse in={expandedItems[item._id]} timeout="auto" unmountOnExit>
                                    <CardContent>
                                        <Card sx={{ maxWidth: "100%", }}>
                                            <CardContent>
                                                {
                                                    item.answer &&
                                                    <Typography variant="body1" color="text.primary">
                                                        {item.answer}
                                                    </Typography>
                                                }
                                            </CardContent>
                                        </Card>
                                    </CardContent>
                                </Collapse>
                            </Card>
                        ))
                    }
                </Stack>
                <Stack alignItems={"center"} justifyContent={"center"} p={2} mt={5}>
                    <Pagination
                        color='primary'
                        component="div"
                        count={maxRecords}
                        page={page}
                        onChange={(e, newValue) => setPage(newValue)}
                    />
                </Stack>
            </Stack>
        </Stack>
    )
}

export default UnitQandA