import { Avatar, Box, Card, Stack, Typography } from '@mui/material'
import { capitalCase } from 'change-case'
import moment from 'moment'
import React from 'react'
import { useSelector } from 'react-redux'

function Profile() {
    const details = useSelector((state) => state.student.currentStudent)
    return (
        <Card>
            <Stack sx={{ width: "100%", height: "100%" }}>
                <Stack sx={{ backgroundColor: "#005081", borderRadius: "8px 8px 0 0" }} alignItems={"center"} p={4} gap={1}>
                    <Stack sx={{ borderRadius: "50%", background: "white", width: "100px", height: "100px" }} alignItems={"center"} justifyContent={"center"}>
                        <Avatar sx={{ width: "100px", height: "100px" }} src={details.profilePic} alt={details.fullname} />
                    </Stack>
                    <Stack>
                        <Typography variant='h4' color={"white"}>{details?.fullname && capitalCase(details?.fullname)}</Typography>
                        <Typography variant='body2' color={"white"}>Student</Typography>
                    </Stack>
                </Stack>

                <Stack p={3}>
                    <Stack p={1} sx={{ border: "1px solid gray", borderBottom: "none" }}>
                        <Typography variant='body1'>{details?.email}</Typography>
                        <Typography variant='caption'>Email Address</Typography>
                    </Stack>
                    <Stack p={1} sx={{ border: "1px solid gray", borderRadius: "0 0 5px 5px" }}>
                        <Typography variant='body1'>{moment(details?.createdAt).format("MMM Do YYYY")}</Typography>
                        <Typography variant='caption'>Member Since</Typography>
                    </Stack>
                </Stack>
            </Stack>
        </Card>
    )
}

export default Profile