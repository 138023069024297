import React, { useEffect, useState } from 'react';
import { capitalCase, sentenceCase } from 'change-case';
import { toast } from 'react-toastify';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import * as Yup from "yup"
import { FilterAlt } from '@mui/icons-material';
import { Form, FormikProvider, useFormik } from 'formik';
import Currency from "react-currency-formatter"
import { useSelector } from 'react-redux';
// material
import {
    Card,
    Table,
    Stack,
    Avatar,
    Button,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText,
    TextField,
    Modal,
    Box,
    Backdrop,
    IconButton,
    Tooltip,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@mui/material';
import { ScaleLoader } from 'react-spinners';
// components
import Page from '../Page';
import Label from '../Label';
import Scrollbar from '../Scrollbar';
import Iconify from '../Iconify';
import SearchNotFound from '../SearchNotFound';
import { SuccessOrdersListHead, SuccessOrdersListToolbar, SuccessOrdersMoreMenu } from '../../sections/@dashboard/successOrders';

const url = process.env.REACT_APP_API_URL;
// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'slno', label: 'Sl.no', alignRight: false },
    { id: 'userName', label: 'Username', alignRight: false },
    { id: 'date', label: 'Date', alignRight: false },
    { id: "invoiceuNumber", label: "Invoice Number", alignRight: false },
    { id: "razorpayOrderId", label: "RazorPay order Id", alignRight: false },
    { id: 'price', label: 'Price', alignRight: false },
    { id: '' },
];

// ----------------------------------------------------------------------

export default function SuccessOrders() {

    const [page, setPage] = useState(0);

    const [filterName, setFilterName] = useState('');

    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [maxRecords, setMaxRecords] = useState(0);

    const [orders, setOrders] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    const accType = localStorage.getItem("accType");
    const { currentAdmin } = useSelector((state) => state.admin);



    const navigate = useNavigate();

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };


    const fetchOrders = async () => {
        try {
            setIsLoading(true)
            const res = await axios.get(`${url}/order/instructor/all?instructorId=${currentAdmin._id}&paymentStatus=SUCCESS&page=${page}&size=${rowsPerPage}`);
            setOrders(res.data.data.orders)
            setMaxRecords(res.data.data.maxRecords)
            setIsLoading(false)
        } catch (e) {
            console.log(e)
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchOrders();
    }, [page, rowsPerPage, rowsPerPage, filterName]);

    const handleRowsPerPageChange = (event) => {
        const newRowsPerPage = event.target.value;
        const currentPage = Math.floor((page * rowsPerPage) / newRowsPerPage);
        setRowsPerPage(newRowsPerPage);
        setPage(currentPage);
    };

    return (
        <>
            <Page title="Orders">
                <Container maxWidth={"xl"}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            Success Orders
                        </Typography>
                    </Stack>
                    <Card>
                        <Stack px={3} direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                            <SuccessOrdersListToolbar filterName={filterName} onFilterName={handleFilterByName} />
                        </Stack>
                        <Scrollbar>
                            <TableContainer sx={{ minWidth: 800 }}>
                                <Table>
                                    <SuccessOrdersListHead
                                        headLabel={TABLE_HEAD}
                                        rowCount={orders?.length}
                                    />
                                    <TableBody>
                                        {
                                            isLoading ? (
                                                <TableRow>
                                                    <TableCell colSpan={TABLE_HEAD.length}>
                                                        <Stack
                                                            alignItems="center"
                                                            justifyContent="center"
                                                            minHeight="200px"
                                                        >
                                                            <ScaleLoader color="black" loading={isLoading} />
                                                        </Stack>
                                                    </TableCell>
                                                </TableRow>

                                            ) : (

                                                orders?.map((row, index) => {
                                                    return (
                                                        <TableRow
                                                            hover
                                                            key={row._id}
                                                            tabIndex={-1}
                                                        >
                                                            <TableCell padding="normal">
                                                                {page * rowsPerPage + index + 1}
                                                            </TableCell>
                                                            <TableCell component="th" scope="row" padding="none">
                                                                <Stack direction="row" alignItems="center" spacing={2}>
                                                                    <Typography variant="subtitle2" >
                                                                        {moment(row?.createdAt).format("ll")}
                                                                    </Typography>
                                                                </Stack>
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                <Button sx={{ gap: "10px" }} onClick={() => navigate(`/dashboard/students/view?id=${row?.userDetails?._id}`)}>
                                                                    <Avatar alt={row?.userDetails?.fullname} src={row?.userDetails?.profilePic} />  {capitalCase(row?.userDetails?.fullname)}
                                                                </Button>
                                                            </TableCell>
                                                            <TableCell align="left">{row?.invoiceNumber}</TableCell>
                                                            <TableCell align="left">{row?.razorpayOrderId}</TableCell>

                                                            <TableCell align="left"><Currency currency={"INR"} quantity={row?.price} /></TableCell>
                                                            <TableCell align="left">{row?.grandTotal}</TableCell>
                                                            <TableCell align="center">
                                                                <Tooltip title="View">
                                                                    <IconButton onClick={() => navigate(`/dashboard/orders/view?id=${row?._id}`)}>
                                                                        <Iconify icon={"carbon:view-filled"} width={22} height={22} />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })
                                            )}
                                    </TableBody>


                                </Table>
                            </TableContainer>
                        </Scrollbar>

                        <TablePagination
                            rowsPerPageOptions={[10, 20, 30]}
                            component="div"
                            count={maxRecords}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={(e, newValue) => setPage(newValue)}
                            onRowsPerPageChange={handleRowsPerPageChange}
                            showFirstButton showLastButton
                        />
                    </Card>
                </Container>
            </Page>

        </>
    );
}
