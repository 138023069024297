import Editor from "@monaco-editor/react";
import { Edit } from '@mui/icons-material';
import { Box, Button, Container, FormControl, Grid, InputLabel, MenuItem, Modal, Pagination, Select, Stack, TextField, Typography } from '@mui/material';
import axios from 'axios';
import { Form, FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ScaleLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import * as Yup from "yup";
import Iconify from '../components/Iconify';
import Page from '../components/Page';

const url = process.env.REACT_APP_API_URL;

const TemplateSchema = Yup.object().shape({
    name: Yup.string().required('Name must not be empty'),
    // template: Yup.string().required("Template not empty"),
    isBlocked: Yup.boolean().optional(),
    studentName: Yup.boolean().optional(),
    studentEmail: Yup.boolean().optional(),
    courseName: Yup.boolean().optional(),
    issueDate: Yup.boolean().optional(),
});

function CertificatesPage() {

    const [certificateTemplates, setCertificateTemplates] = useState([]);
    const [open, setOpen] = useState(false);
    const [page, setPage] = useState(1);
    const [maxRecords, setMaxRecords] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [isLoading, setIsLoading] = useState(false);

    const [value, setValue] = useState("");

    const handleEditorChange = (value) => {
        setValue(value);
    };

    const navigate = useNavigate();

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const fetchCertificates = async () => {
        try {
            setIsLoading(true)
            const res = await axios.get(`${url}/certificate-template/all?page=${page > 0 ? page - 1 : 0}&size=${rowsPerPage}`);
            setCertificateTemplates(res.data.data.records);
            setMaxRecords(Math.ceil(res.data.data.maxRecords / rowsPerPage));
            setIsLoading(false)
        } catch (e) {
            setIsLoading(false)
            console.log(e)
        }
    };

    useEffect(() => {
        fetchCertificates();
    }, [])


    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        height: 600,
        bgcolor: 'background.paper',
        overflowY: 'scroll',
        // border: '2px solid #000', 
        borderRadius: "8px",
        boxShadow: 24,
        p: 4,
    };


    const formik = useFormik({
        initialValues: {
            name: "",
            // template: "",
            isBlocked: false,
            studentName: false,
            studentEmail: false,
            courseName: false,
            issueDate: false,
        },
        validationSchema: TemplateSchema,
        onSubmit: async (values, { resetForm }) => {
            try {
                const res = await axios.post(`${url}/certificate-template/`, {
                    ...values,
                    template: value
                });
                if (!res.data.isError) {
                    toast.success(res.data.message);
                    fetchCertificates();
                    handleClose();
                }
            } catch (e) {
                console.log(e)
                toast.error(e.response.data.message)
            }
        }
    });

    const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue } = formik;

    return (

        <Page title="Certificates">
            <Container maxWidth={"xl"}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Certificate Templates
                    </Typography>
                    <Button variant="contained" onClick={handleOpen} startIcon={<Iconify icon="eva:plus-fill" />}>
                        Create Template
                    </Button>
                </Stack>
                <Stack>
                    {
                        isLoading ? (
                            <Stack width={"100%"} height={"500px"} alignItems={"center"} justifyContent={"center"}>
                                <ScaleLoader />
                            </Stack>
                        ) : (
                            <Grid container rowGap={3} columnGap={2}>
                                {
                                    certificateTemplates?.map((template) => (
                                        <>
                                            <Grid item xs={12} md={4} sx={{ position: "relative", }}>
                                                {/* <Button variant='contained' sx={{ position: "absolute", right: "0", zIndex: "888" }} onClick={() => navigate(`edit?id=${template._id}`)}>
                                                    <Edit />   Edit
                                                </Button> */}
                                                <Typography sx={{ pt: 4 }} dangerouslySetInnerHTML={{
                                                    __html: template?.template
                                                }}
                                                />
                                            </Grid>
                                        </>
                                    ))
                                }
                            </Grid>
                        )
                    }

                    <Stack alignItems={"center"} justifyContent={"center"} p={2} mt={5}>
                        <Pagination
                            color='primary'
                            component="div"
                            count={maxRecords}
                            page={page}
                            onChange={(e, newValue) => setPage(newValue)}
                        />
                    </Stack>
                </Stack>

                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <FormikProvider value={formik}>
                            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                                <Stack gap={2}>
                                    <Typography id="modal-modal-title" variant="h6" component="h2">
                                        Create Template
                                    </Typography>
                                    <TextField
                                        name="name"
                                        label="Name"
                                        value={values.name}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={touched.name && errors.name}
                                        helperText={touched.name && errors.name}
                                    />
                                    <Stack sx={{
                                        border: "1px solid black",
                                        borderRadius: "8px",
                                        p: 2
                                    }}>

                                        <Editor
                                            height="300px"
                                            width={`100%`}
                                            language={"html"}
                                            value={value}
                                            theme={{ value: "oceanic-next", label: "Oceanic Next" }}
                                            defaultValue="<!-- Enter HTML code  -->"
                                            onChange={handleEditorChange}
                                        />
                                    </Stack>
                                    {/* <TextField
                                    // multiline
                                    // minRows={2}
                                    // name="template"
                                    // label="Template"
                                    // value={values.template}
                                    // onChange={handleChange}
                                    // onBlur={handleBlur}
                                    // error={touched.template && errors.template}
                                    // helperText={touched.template && errors.template}
                                    /> */}


                                    <FormControl fullWidth margin="none" helperText={touched.isBlocked && errors.isBlocked}>
                                        <InputLabel id="demo-simple-select-label" sx={{ color: `${touched.isBlocked && errors.isBlocked && "red"}` }}>
                                            Status
                                        </InputLabel>
                                        <Select
                                            name="isBlocked"
                                            error={touched.isBlocked && errors.isBlocked}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="Status"
                                            value={values.isBlocked}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        >
                                            <MenuItem value={true}>Blocked</MenuItem>
                                            <MenuItem value={false}>Active</MenuItem>
                                        </Select>
                                        {touched.isBlocked && errors.isBlocked && (
                                            <p style={{ color: "red", fontWeight: 400, fontSize: "0.75em", marginTop: 2 }}>{errors.isBlocked}</p>
                                        )}
                                    </FormControl>

                                    <FormControl fullWidth margin="none" helperText={touched.studentName && errors.studentName}>
                                        <InputLabel id="demo-simple-select-label" sx={{ color: `${touched.studentName && errors.studentName && "red"}` }}>
                                            Student Name
                                        </InputLabel>
                                        <Select
                                            name="studentName"
                                            error={touched.studentName && errors.studentName}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="Student Name"
                                            value={values.studentName}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        >
                                            <MenuItem value={true}>Yes</MenuItem>
                                            <MenuItem value={false}>No</MenuItem>
                                        </Select>
                                        {touched.studentName && errors.studentName && (
                                            <p style={{ color: "red", fontWeight: 400, fontSize: "0.75em", marginTop: 2 }}>{errors.studentName}</p>
                                        )}
                                    </FormControl>

                                    <FormControl fullWidth margin="none" helperText={touched.studentEmail && errors.studentEmail}>
                                        <InputLabel id="demo-simple-select-label" sx={{ color: `${touched.studentEmail && errors.studentEmail && "red"}` }}>
                                            Student Email
                                        </InputLabel>
                                        <Select
                                            name="studentEmail"
                                            error={touched.studentEmail && errors.studentEmail}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="Student Email"
                                            value={values.studentEmail}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        >
                                            <MenuItem value={true}>Yes</MenuItem>
                                            <MenuItem value={false}>No</MenuItem>
                                        </Select>
                                        {touched.studentEmail && errors.studentEmail && (
                                            <p style={{ color: "red", fontWeight: 400, fontSize: "0.75em", marginTop: 2 }}>{errors.studentEmail}</p>
                                        )}
                                    </FormControl>

                                    <FormControl fullWidth margin="none" helperText={touched.courseName && errors.courseName}>
                                        <InputLabel id="demo-simple-select-label" sx={{ color: `${touched.courseName && errors.courseName && "red"}` }}>
                                            Course Name
                                        </InputLabel>
                                        <Select
                                            name="courseName"
                                            error={touched.courseName && errors.courseName}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="Course Name"
                                            value={values.courseName}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        >
                                            <MenuItem value={true}>Yes</MenuItem>
                                            <MenuItem value={false}>No</MenuItem>
                                        </Select>
                                        {touched.courseName && errors.courseName && (
                                            <p style={{ color: "red", fontWeight: 400, fontSize: "0.75em", marginTop: 2 }}>{errors.courseName}</p>
                                        )}
                                    </FormControl>

                                    <FormControl fullWidth margin="none" helperText={touched.issueDate && errors.issueDate}>
                                        <InputLabel id="demo-simple-select-label" sx={{ color: `${touched.issueDate && errors.issueDate && "red"}` }}>
                                            Issue Date
                                        </InputLabel>
                                        <Select
                                            name="issueDate"
                                            error={touched.issueDate && errors.issueDate}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="Issue Date"
                                            value={values.issueDate}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        >
                                            <MenuItem value={true}>Yes</MenuItem>
                                            <MenuItem value={false}>No</MenuItem>
                                        </Select>
                                        {touched.issueDate && errors.issueDate && (
                                            <p style={{ color: "red", fontWeight: 400, fontSize: "0.75em", marginTop: 2 }}>{errors.issueDate}</p>
                                        )}
                                    </FormControl>


                                    <Button type='submit' variant='contained'>Create</Button>
                                </Stack>
                            </Form>
                        </FormikProvider>

                    </Box>
                </Modal>


            </Container>
        </Page>
    )
}

export default CertificatesPage