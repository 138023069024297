import { ArrowBack, Edit } from '@mui/icons-material';
import { Button, Card, IconButton, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Page from '../components/Page';

const url = process.env.REACT_APP_API_URL;

function ContactUsView() {

    const [query, setQuery] = useState({});
    const [status, setStatus] = useState("");
    const [notes, setNotes] = useState("");
    const [editMode, setEditMode] = useState(false);



    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const id = searchParams.get("id");

    const fetchQuery = async () => {
        try {
            const res = await axios.get(`${url}/contact-us/admin/one?id=${id}`);
            setQuery(res.data.data);
            setStatus(res.data.data.status);
            setNotes(res.data.data.notes);
        } catch (e) {
            console.log(e);
        }
    };

    const handleStatusChange = async (e) => {
        try {
            const res = await axios.put(`${url}/contact-us/admin/status`, {
                id,
                status: e.target.value,
            });
            if (res.data.isError) {
                toast.error(res.data.message);
            } else {
                toast.success(res.data.message);
                fetchQuery();
            }
        } catch (e) {
            toast.error(e.response.data.message);
            console.log(e);
        }
    };

    const handleUpdateNotes = async () => {
        try {
            const res = await axios.put(`${url}/contact-us/admin/notes`, {
                id,
                notes,
            });
            if (res.data.isError) {
                toast.error(res.data.message);
            } else {
                setEditMode(false);
                toast.success(res.data.message);
                fetchQuery();
            }
        } catch (e) {
            toast.error(e.response.data.message);
            console.log(e);
        }
    };


    useEffect(() => {
        fetchQuery();
    }, [id]);


    return (
        <Page title="Query">
            <>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
                    <Typography variant="h5" gutterBottom>
                        <IconButton onClick={() => navigate(-1)}><ArrowBack /></IconButton> &nbsp;
                        Query Details
                    </Typography>
                </Stack>

                <Stack>
                    <Card>
                        <Stack p={3} gap={2}>
                            <Stack direction="row" justifyContent="space-between">
                                <Stack>
                                    <Typography variant="body1" fontWeight={"bold"}>
                                        Fullname
                                    </Typography>
                                    <Typography variant="body1">
                                        {query?.name}
                                    </Typography>
                                </Stack>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={status}
                                    disabled={query.status === "RESPONDED"}
                                    onChange={handleStatusChange}
                                    size='small'
                                >
                                    <MenuItem value={"PENDING"}>Pending</MenuItem>
                                    <MenuItem value={"RESPONDED"}>Responded</MenuItem>
                                </Select>
                            </Stack>
                            <Stack>
                                <Typography variant="body1" fontWeight={"bold"}>
                                    Email
                                </Typography>
                                <Typography variant="body1">
                                    {query?.email}
                                </Typography>
                            </Stack>
                            <Stack>
                                <Typography variant="body1" fontWeight={"bold"}>
                                    Contact Number
                                </Typography>
                                <Typography variant="body1">
                                    {query?.contactNumber}
                                </Typography>
                            </Stack>
                            <Stack>
                                <Typography variant="body1" fontWeight={"bold"}>
                                    Date
                                </Typography>
                                <Typography variant="body1">
                                    {moment(query?.createdAt).format("LLLL")}
                                </Typography>
                            </Stack>
                            <Stack>
                                <Typography variant="body1" fontWeight={"bold"}>
                                    Message
                                </Typography>
                                <Typography variant="body1">
                                    {query?.message}
                                </Typography>
                            </Stack>
                            <Stack>
                                {!editMode &&
                                    <Stack direction={"column"} gap={1} ><Stack direction={"row"} gap={1} alignItems={"center"} >
                                        <Typography variant="body1" fontWeight={"bold"}>
                                            Notes
                                        </Typography>
                                        <IconButton onClick={() => setEditMode(true)}>
                                            <Edit />
                                        </IconButton>
                                    </Stack>
                                        <Typography variant="body1">
                                            {query?.notes}
                                        </Typography>
                                    </Stack>
                                }
                                {
                                    editMode && <Stack direction={"column"} gap={1} >
                                        <TextField
                                            id="outlined-multiline-static"
                                            label="Notes"
                                            multiline
                                            rows={4}
                                            fullWidth
                                            value={notes}
                                            onChange={(e) => setNotes(e.target.value)}
                                        />
                                        <Stack direction={"row"} gap={1} alignItems={"center"} justifyContent={"flex-end"}>
                                            <Button variant={"outlined"} onClick={() => setEditMode(false)}>Cancel</Button>
                                            <Button variant={"contained"} onClick={handleUpdateNotes}>Save</Button>
                                        </Stack>
                                    </Stack>
                                }

                            </Stack>
                        </Stack>
                    </Card>
                </Stack>

            </>
        </Page>
    );
}

export default ContactUsView;