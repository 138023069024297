import { Box, Stack, Typography } from '@mui/material'
import moment from 'moment'
import React from 'react'
import { useSelector } from 'react-redux'

function Profile() {
    const details = useSelector((state) => state.instructor.currentInstructor)
    return (
        <>
            <Stack sx={{ width: "100%", borderRadius: "8px", boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" }}>
                <Stack sx={{ backgroundColor: (theme) => theme.palette.primary.main, borderRadius: "8px 8px 0 0" }} alignItems={"center"} p={4} gap={1}>
                    <Stack sx={{ borderRadius: "50%", background: "white", width: "100px" }}>
                        <Box borderRadius={"50%"} component={"img"} alt='profile' src={details?.profilePic ? details.profilePic : 'https://bootdey.com/img/Content/avatar/avatar7.png'} />
                    </Stack>
                    <Stack>
                        <Typography variant='h4' color={"white"}>{details?.fullname}</Typography>
                        <Typography variant='body2' color={"white"}>Instructor</Typography>
                    </Stack>
                </Stack>

                <Stack p={3}>
                    <Stack p={1} sx={{ border: "1px solid gray", borderBottom: "none" }}>
                        <Typography variant='body1'>{details?.email}</Typography>
                        <Typography variant='caption'>Email Address</Typography>
                    </Stack>
                    <Stack p={1} sx={{ border: "1px solid gray", borderRadius: "0 0 5px 5px" }}>
                        <Typography variant='body1'>{moment(details?.createdAt).format("MMM Do YYYY")}</Typography>
                        <Typography variant='caption'>Member Since</Typography>
                    </Stack>
                </Stack>
            </Stack>
        </>
    )
}

export default Profile