import { Edit } from '@mui/icons-material'
import { Button, FormControl, Icon, InputLabel, Menu, MenuItem, Select, Stack, Tooltip, Typography } from '@mui/material'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { toast } from 'react-toastify'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ScaleLoader } from 'react-spinners'
import BackButton from '../components/BackButton'
import Page from '../components/Page'
import Banner from '../components/courseDetails/Banner'
import Iconify from '../components/Iconify'
import CourseDetailsLayoutWeb from '../components/courseDetails/CourseDetailsLayoutWeb'
import { fetchCourseFailure, fetchCourseStart, fetchCourseSuccess, pageReload } from '../redux/courseSlice'


const url = process.env.REACT_APP_API_URL;

function CourseDetails() {

    const [versions, setVersions] = useState([]);


    const dispatch = useDispatch();
    const { reload, isLoading, currentCourse } = useSelector((state) => state.course)

    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const id = searchParams.get("id")


    const fetchData = async () => {
        try {
            dispatch(fetchCourseStart());
            const res = await axios.get(`${url}/course/get?id=${id}`);
            dispatch(fetchCourseSuccess(res.data.data.result))
            setSelectedVersion(res.data.data.result.version)
        } catch (e) {
            dispatch(fetchCourseFailure())
            console.log(e)
        }
    };

    useEffect(() => {
        fetchData();
    }, [id, reload])


    const [selectedVersion, setSelectedVersion] = useState("");


    const handleVersionChange = (event) => {
        setSelectedVersion(event.target.value);
    };

    const handleGoToVersion = (courseId) => {
        if (selectedVersion) {
            navigate(`/dashboard/courses/details?id=${courseId}`);
        } else {
            toast.error("Please select a version")
        }
    };




    const handleCloneCourse = async () => {
        try {
            const res = await axios.post(`${url}/course/instructor/clone?courseId=${id}`);
            if (!res.data.isError) {
                dispatch(pageReload())
                toast.success(res.data.message)
            } else {
                toast.error(res.data.message)
            }
        } catch (e) {
            toast.error(e.response.data.message)
            console.log(e)
        }
    }

    const getVersions = async () => {
        try {
            const res = await axios.get(`${url}/course/version-control?courseNumber=${currentCourse.courseNumber}`);
            setVersions(res.data.data.versions)
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        getVersions();
    }, [currentCourse])


    return (
        <Page title="Course">
            {
                isLoading ? (
                    <Stack height={"500px"} alignItems={"center"} justifyContent={"center"} width={"100%"}>
                        <ScaleLoader />
                    </Stack>
                ) : (
                    <>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
                            <Stack gap={3} direction={"row"} alignItems={"center"}>
                                <Typography variant="h4" >
                                    <BackButton link={"/dashboard/courses"} />   Course Details
                                </Typography>

                                <FormControl sx={{ width: "250px", }}>
                                    <InputLabel id="version-dropdown-label">Versions</InputLabel>
                                    <Select
                                        labelId="version-dropdown-label"
                                        id="version-dropdown"
                                        label={"versions"}
                                        value={selectedVersion}
                                        onChange={handleVersionChange}
                                    >
                                        {versions.map((version) => (
                                            <MenuItem key={version.value} value={version.value} onClick={() => handleGoToVersion(version.courseId)}>
                                                {version.label}
                                            </MenuItem>
                                        ))}
                                    </Select>

                                </FormControl>

                                {/* <Button variant='contained' onClick={handleGoToVersion}>
                                    Go to Version
                                </Button> */}

                            </Stack>
                            <Stack direction={"row"} gap={2}>

                                {
                                    currentCourse?.isFinalized ?
                                        <Button onClick={handleCloneCourse} variant='contained' startIcon={<Iconify icon={"clarity:clone-solid"} />}>Clone Course</Button>
                                        :
                                        <Tooltip arrow title={"Course is not finalized"} placement='bottom'>
                                            <span>
                                                <Button disabled variant='contained' startIcon={<Iconify icon={"clarity:clone-solid"} />}>Clone Course</Button>
                                            </span>
                                        </Tooltip>
                                }

                                <Button onClick={() => navigate("/dashboard/courses/edit")} variant='contained'><Edit />&nbsp;&nbsp;Edit course</Button>
                            </Stack>
                        </Stack>

                        <Stack>
                            <Banner />
                            <Stack display={{ xs: "flex", lg: "flex" }}>
                                <CourseDetailsLayoutWeb />
                            </Stack>
                        </Stack>
                    </>
                )
            }
        </Page>
    )
}

export default CourseDetails