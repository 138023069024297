
import { Star } from '@mui/icons-material';
import axios from 'axios';
import { capitalCase, sentenceCase } from 'change-case';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from "yup";
// material
import {
    Box,
    Button,
    Card,
    IconButton,
    Rating,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    Tooltip,
    Typography
} from '@mui/material';
import { ScaleLoader } from 'react-spinners';
// components
import Iconify from '../Iconify';
import Label from '../Label';
import Scrollbar from '../Scrollbar';
import { CourseListHead, CourseListToolbar } from '../../sections/@dashboard/courses';
// mock

const url = process.env.REACT_APP_API_URL;
// ----------------------------------------------------------------------

const StudentSchema = Yup.object().shape({
    fullname: Yup.string().required('Fullname is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    status: Yup.string().required('Status is required'),
    password: Yup.string().min(8).required("password is requred")
});

const TABLE_HEAD = [
    { id: 'slno', label: 'Sl.no', alignRight: false },
    { id: 'courseName', label: 'Title', alignRight: false },
    { id: 'duration ', label: 'Duration', alignRight: false },
    { id: 'totalModules', label: 'Modules', alignRight: false },
    { id: 'instructor', label: 'Instructor', alignRight: false },
    { id: 'finalized', label: 'Finalized', alignRight: false },
    { id: 'students', label: 'Enrolled', alignRight: false },
    { id: 'rating', label: 'Rating', alignRight: false },
    { id: 'isBlocked', label: 'Status', alignRight: false },
    { id: '', label: "View" },
];



function ArchivedCourses() {

    const [page, setPage] = useState(0);

    const [filterName, setFilterName] = useState('');

    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [maxRecords, setMaxRecords] = useState(0);

    const [courses, setCourses] = useState([]);

    const navigate = useNavigate();

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };

    const [isLoading, setIsLoading] = useState(false);

    const currentAdmin = useSelector((state) => state.admin.currentAdmin)


    const fetchCoursesForAdmin = async () => {
        try {
            setIsLoading(true)
            const res = await axios.get(`${url}/course/instructor/getall?page=${page}&size=${rowsPerPage}&title=${filterName}&isArchived=true`);
            setCourses(res.data.data.records)
            setMaxRecords(res.data.data.maxRecords)
            setIsLoading(false)
        } catch (e) {
            setIsLoading(false)
            console.log(e)
        }
    }

    const formik = useFormik({
        initialValues: {
            fullname: "",
            email: "",
            status: "",
            password: "",
        },
        validationSchema: StudentSchema,
        onSubmit: async (values, { resetForm }) => {
            try {
                const res = await axios.post(`${url}/user/create`, {
                    fullname: values.fullname,
                    email: values.email,
                    hash: values.password,
                    isBlocked: values.status
                });
                if (!res.data.isError) {
                    toast.success(res.data.message)
                    resetForm();
                    fetchCoursesForAdmin();
                } else {
                    toast.error(res.data.message)
                    console.log(res.data.data);

                }
            } catch (e) {
                toast.error(e.response.data.message)
                console.log(e);
            }
        }
    });

    useEffect(() => {
        fetchCoursesForAdmin();
    }, [page, rowsPerPage, filterName, currentAdmin._id]);


    const [ratings, setRatings] = useState({});


    const formatRating = (rating) => {
        const inputString = rating;
        const numberValue = parseFloat(inputString);
        const roundedValue = Math.round(numberValue * 2) / 2;
        const roundedString = roundedValue.toString();
        return roundedString;
    };

    function formatDuration(duration) {
        if (duration >= 1440) {
            const days = Math.floor(duration / 1440);
            return `${days} D${days > 1 ? '' : ''}`;
        } else if (duration >= 60) {
            const hours = Math.floor(duration / 60);
            return `${hours} Hr${hours > 1 ? '' : ''}`;
        } else {
            return `${duration} Min${duration > 1 ? '' : ''}`;
        }
    }

    const handleRowsPerPageChange = (event) => {
        const newRowsPerPage = event.target.value;
        const currentPage = Math.floor((page * rowsPerPage) / newRowsPerPage);
        setRowsPerPage(newRowsPerPage);
        setPage(currentPage);
    };

    return (
        <Card>
            <Stack px={3} direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                <CourseListToolbar filterName={filterName} onFilterName={handleFilterByName} />
            </Stack>
            <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                        <CourseListHead
                            headLabel={TABLE_HEAD}
                            rowCount={courses.length}
                        />

                        <TableBody>
                            {
                                isLoading ? (
                                    <TableRow>
                                        <TableCell colSpan={TABLE_HEAD.length}>
                                            <Stack
                                                alignItems="center"
                                                justifyContent="center"
                                                minHeight="200px"
                                            >
                                                <ScaleLoader color="black" loading={isLoading} />
                                            </Stack>
                                        </TableCell>
                                    </TableRow>

                                ) : (
                                    courses?.map((row, index) => {
                                        const courseRatings = ratings[row._id] || {};
                                        const inputString = courseRatings.averageRating;
                                        const numberValue = parseFloat(inputString);
                                        const roundedValue = Math.round(numberValue * 2) / 2;
                                        const roundedString = roundedValue.toString();

                                        return (
                                            <TableRow
                                                hover
                                                key={row._id}
                                                tabIndex={-1}
                                            >
                                                <TableCell padding="normal">
                                                    {page * rowsPerPage + index + 1}
                                                </TableCell>
                                                <TableCell component="th" scope="row" padding="none">
                                                    <Stack direction="row" alignItems="center" spacing={2}>
                                                        <Box component={"img"} width={"80px"} height={"50px"} p={1} borderRadius={"8px"} alt={sentenceCase(row.courseName)} src={row.image} />
                                                        <div style={{ overflow: "hidden", textOverflow: "ellipsis", width: '12rem', }}>
                                                            <Typography noWrap variant="subtitle2"  >
                                                                {row?.courseName?.toUpperCase()}
                                                            </Typography>
                                                        </div>
                                                    </Stack>
                                                </TableCell>
                                                <TableCell align="left">{formatDuration(row.duration)}</TableCell>
                                                <TableCell align="left">{row?.modules?.length}</TableCell>
                                                <TableCell align="left">{row.instructorDocs.map((item) => capitalCase(item.fullname)).join(", ")}</TableCell>
                                                <TableCell align="left">  <Label variant="outlined" color={(!row.isFinalized && 'error') || 'success'}>
                                                    {sentenceCase(row.isFinalized ? "Yes" : "No")}
                                                </Label></TableCell>
                                                <TableCell align="left">{row?.purchasedUsersCount}</TableCell>
                                                <TableCell align="left">
                                                    <Stack direction={"row"} alignItems={"center"} gap={1} justifyContent={"flex-start"}>
                                                        <Stack direction={"row"} gap={0.5} alignItems={"center"}>
                                                            <Typography variant='subtitle1'>{formatRating(row?.averageRating)}</Typography>
                                                            <Rating
                                                                size='small'
                                                                name="text-feedback"
                                                                value={formatRating(row?.averageRating)}
                                                                readOnly
                                                                precision={0.5}
                                                                emptyIcon={<Star sx={{ opacity: 0.55 }} fontSize="inherit" />}
                                                            />
                                                            <Typography variant='subtitle2'>({row.totalRatings})</Typography>
                                                        </Stack>
                                                    </Stack>
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Label variant="ghost" color={(row.isBlocked && 'error') || 'success'}>
                                                        {sentenceCase(row.isBlocked ? "blocked" : "active")}
                                                    </Label>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Tooltip title="View">
                                                        <IconButton onClick={() => navigate(`/dashboard/courses/details?id=${row._id}`)}>
                                                            <Iconify icon={"carbon:view-filled"} width={22} height={22} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })
                                )
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Scrollbar>

            <TablePagination
                rowsPerPageOptions={[10, 20, 30]}
                component="div"
                count={maxRecords}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(e, newValue) => setPage(newValue)}
                onRowsPerPageChange={handleRowsPerPageChange}
                showFirstButton showLastButton
            />
        </Card>
    )
}

export default ArchivedCourses