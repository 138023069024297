import { ArrowBackIos } from '@mui/icons-material';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Paper,
    Stack,
    Typography
} from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const url = process.env.REACT_APP_API_URL;

const AssignmentView = () => {
    const [assignment, setAssignment] = useState({});
    const [open, setOpen] = useState(false);

    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const assignmentId = searchParams.get('id');
    const { currentCourse } = useSelector((state) => state.course);

    const fetchAssignment = async () => {
        try {
            const res = await axios.get(`${url}/assignment/get-one?id=${assignmentId}&courseId=${currentCourse._id}`);
            setAssignment(res.data.data);
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        fetchAssignment();
    }, [assignmentId]);

    const handleFinalizeAssignment = async () => {
        try {
            await axios.put(`${url}/assignment/update`, {
                id: assignmentId,
                isFinalized: true,
            });
            toast.success('Assignment has been finalized');
            handleClose();
            fetchAssignment();
        } catch (e) {
            toast.error(e.response.data.message);
            console.log(e);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const styles = {
        container: {
            padding: '20px',
            maxWidth: '600px',
            margin: 'auto',
        },
        header: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: "10px"
        },
        title: {
            fontSize: '20px',
            fontWeight: 'bold',
        },
        title1: {
            fontSize: '24px',
            fontWeight: 'bold',
        },
        description: {
            marginTop: '20px',
            marginBottom: '20px',
        },
        finalizeButton: {
            backgroundColor: (theme) => theme.palette.primary.main,
            color: '#fff',
            '&:hover': {
                backgroundColor: (theme) => theme.palette.primary.dark,
            },
        },
    };

    return (
        <Paper style={styles.container}>
            <div style={styles.header}>
                <Typography variant="h5" style={styles.title}>
                    Assignment Information
                </Typography>
                <IconButton onClick={() => navigate(-1)}>
                    <ArrowBackIos />
                </IconButton>
            </div>
            <Typography style={styles.title1}>
                {assignment.title}
            </Typography>
            <Typography
                className='markdownBlog'
                dangerouslySetInnerHTML={{ __html: assignment?.instructions }}
            />
            <Typography>
                <strong>Total Marks:</strong> {assignment.totalMarks}
            </Typography>
            <Typography>
                <strong>Pass Mark:</strong> {assignment.passMark}
            </Typography>
            <Typography>
                <strong>Status:</strong> <Button sx={{ height: "25px" }} variant='outlined' color={assignment.isBlocked ? "error" : "success"}>{assignment.isBlocked ? "Blocked" : "Active"}</Button>
            </Typography>
            <Stack mt={4}>
                {!assignment.isFinalized ? (
                    <Button onClick={() => setOpen(true)} style={styles.finalizeButton} variant='contained' sx={{ color: (theme) => theme.palette.primary.main }}>Finalize the Assignment</Button>
                ) : (
                    <Button disabled variant='contained' >Finalized</Button>
                )}
            </Stack>

            <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">Confirm Finalization</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        By clicking the "Agree" button below, you are confirming that all the required updates are done. This action is irreversible, and once finalized, you will not be able to update or make any changes.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Disagree
                    </Button>
                    <Button onClick={handleFinalizeAssignment} color="primary" autoFocus>
                        Agree
                    </Button>
                </DialogActions>
            </Dialog>
        </Paper>
    );
};

export default AssignmentView;
