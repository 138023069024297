import React, { useEffect, useState } from 'react';
import { sentenceCase } from 'change-case';
import { toast } from 'react-toastify';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import * as Yup from "yup"
import { ErrorMessage, Field, Form, Formik, FormikProvider, useFormik } from 'formik';
import { FadeLoader } from 'react-spinners';
import { Delete, MoreVert } from '@mui/icons-material';
// material
import { red } from '@mui/material/colors';

import {
    Card,
    Table,
    Stack,
    Avatar,
    Button,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText,
    TextField,
    Modal,
    Box,
    Backdrop,
    IconButton,
    Tooltip,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Grid,
    Pagination,
    CardHeader,
    CardMedia,
    CardContent,
    CardActions,
    Collapse,
    Popover,
} from '@mui/material';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';


// mock


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: '8px',
};


const url = process.env.REACT_APP_API_URL;
// ----------------------------------------------------------------------

const TestimonialAddSchema = Yup.object().shape({
    fullname: Yup.string().required('Fullname is required'),
    isBlocked: Yup.string().required('Status is required'),
    description: Yup.string().required("Description is required"),
    picture: Yup.string().required("Image is required")
});

const TestimonialUpdateSchema = Yup.object().shape({
    fullname: Yup.string().required('Fullname is required'),
    isBlocked: Yup.string().required('Status is required'),
    description: Yup.string().required("Description is required"),
    picture: Yup.string(),
});


// ----------------------------------------------------------------------

export default function Testimonials() {

    const [page, setPage] = useState(1);

    const [rowsPerPage, setRowsPerPage] = useState(8);

    const [maxRecords, setMaxRecords] = useState(0);

    const [testimonials, setTestimonials] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    const [open, setOpen] = useState(false);

    const [editOpen, setEditOpen] = useState(false);

    const [openMenu, setOpenMenu] = useState(false);

    const [testimonial, setTestimonial] = useState({});

    const [id, setId] = useState("");

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleEditOpen = () => {
        fetchATestimonial();
        setEditOpen(true);
        setOpenMenu(null)
    };
    const handleEditClose = () => {
        fetchTestimonials();
        setEditOpen(false);
    };

    const fetchTestimonials = async () => {
        try {
            setIsLoading(true)
            const res = await axios.get(`${url}/testimonial/get-all?page=${page > 0 ? page - 1 : page}&size=${rowsPerPage}`);
            setTestimonials(res.data.data.records)
            setMaxRecords(Math.ceil(res.data.data.maxRecords / rowsPerPage))
            setIsLoading(false)
        } catch (e) {
            setIsLoading(false)
            console.log(e)
        }
    }

    const formik = useFormik({
        initialValues: {
            fullname: "",
            isBlocked: false,
            description: "",
            picture: ""
        },
        validationSchema: TestimonialAddSchema,
        onSubmit: async (values, { resetForm }) => {
            const formData = new FormData();
            Object.keys(values).forEach((key) => {
                formData.append(key, values[key]);
            });
            try {
                const res = await axios.post(`${url}/testimonial/create`, formData);
                if (!res.data.isError) {
                    toast.success(res.data.message)
                    setOpen(false)
                    resetForm();
                    fetchTestimonials();
                } else {
                    toast.error(res.data.message)
                    console.log(res.data.data);

                }
            } catch (e) {
                toast.error(e.response.data.message)
                console.log(e);
            }
        }
    });

    const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue } = formik;

    useEffect(() => {
        fetchTestimonials();
    }, [page, rowsPerPage]);

    const handleOpenMenu = (event, id) => {
        setOpenMenu(event.currentTarget);
        setId(id)
    };

    const handleCloseMenu = () => {
        setOpenMenu(null);
        setId("")
    };

    // const statusUpdateHandler = async (item, e) => {
    //     try {
    //         const updatedStatus = e.target.value === 'true';
    //         setStatus(updatedStatus);

    //         const res = await axios.put(`${url}/category/status-update`, {
    //             id: item._id,
    //             isBlocked: updatedStatus,
    //         });

    //         if (!res.data.isError) {
    //             toast.success(res.data.message);
    //             setOpen(false);
    //             // fetchCategories();
    //             const updatedCategories = categories.map(category => {
    //                 if (category._id === item._id) {
    //                     return { ...category, isBlocked: updatedStatus };
    //                 }
    //                 return category;
    //             });
    //             setTestimonials(updatedCategories);
    //         } else {
    //             toast.error(res.data.message);
    //             console.log(res.data.data);
    //         }
    //         console.log(res.data);
    //     } catch (e) {
    //         console.log(e);
    //     }
    // };


    const handleDelete = async () => {
        handleCloseMenu(null)
        try {
            const res = await axios.delete(`${url}/testimonial/delete?id=${id}`);
            if (!res.data.isError) {
                toast.success(res.data.message);
                fetchTestimonials();
            } else {
                toast.error(res.data.message)
            }
        } catch (e) {
            toast.error(e.response.status.message)
            console.log(e)
        }
    };

    const fetchATestimonial = async () => {
        try {
            const res = await axios.get(`${url}/testimonial/get-one?id=${id}`);
            setTestimonial(res.data.data)
            console.log("testimonial", res.data.data)
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <>
            <Page title="Testimonials">
                <Container>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            Testimonials
                        </Typography>
                        <Button variant="contained" onClick={handleOpen} component={RouterLink} to="#" startIcon={<Iconify icon="eva:plus-fill" />}>
                            New Testimonial
                        </Button>
                    </Stack>
                    <Card sx={{ boxShadow: "none", p: "30px" }} >
                        {
                            isLoading ?
                                <Stack alignItems={"center"} justifyContent={"center"} height={"300px"}>
                                    <FadeLoader color="#000" loading={isLoading} size={18} />
                                </Stack>
                                :
                                <Grid container rowGap={3} columnGap={3}>
                                    {
                                        testimonials.map((item) => (
                                            <>
                                                <Grid item xs={12} md={5.8}>
                                                    <Card sx={{ maxWidth: "100%", height: "100%" }}>
                                                        <CardHeader
                                                            avatar={
                                                                <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe" src={item.picture}>
                                                                    {item.fullname}
                                                                </Avatar>
                                                            }
                                                            action={
                                                                <IconButton aria-label="settings" onClick={(e) => handleOpenMenu(e, item._id)}>
                                                                    <MoreVert />
                                                                </IconButton>
                                                            }
                                                            title={sentenceCase(item.fullname)}
                                                            subheader={moment(item.createdAt).format("MMMM Do YYYY")}
                                                        />

                                                        <CardContent>
                                                            <Typography variant="body2" color="text.secondary">
                                                                {item.description}
                                                            </Typography>
                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                            </>
                                        ))
                                    }
                                </Grid>
                        }

                        <Stack alignItems={"center"} justifyContent={"center"} p={2} mt={5}>
                            <Pagination
                                component="div"
                                count={maxRecords}
                                page={page}
                                onChange={(e, newValue) => setPage(newValue)}
                            />
                        </Stack>
                    </Card>
                </Container>

                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <FormikProvider value={formik}>
                            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                                <Typography id="modal-modal-title" variant="h6" component="h2">
                                    New Testimonial
                                </Typography>
                                <Stack gap={3} mt={2}>
                                    <TextField
                                        name="fullname"
                                        label="Fullname"
                                        value={values.fullname}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={touched.fullname && errors.fullname}
                                        helperText={touched.fullname && errors.fullname}
                                    />
                                    <FormControl fullWidth margin="none" helperText={touched.isBlocked && errors.isBlocked}>
                                        <InputLabel id="demo-simple-select-label" sx={{ color: `${touched.isBlocked && errors.isBlocked && "red"}` }}>
                                            Status
                                        </InputLabel>
                                        <Select
                                            name="status"
                                            error={touched.isBlocked && errors.isBlocked}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="Status"
                                            value={values.isBlocked}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        >
                                            <MenuItem value={false}>Active</MenuItem>
                                            <MenuItem value={true}>Blocked</MenuItem>
                                        </Select>
                                        {touched.isBlocked && errors.isBlocked && (
                                            <p style={{ color: "red", fontWeight: 400, fontSize: "0.75em", marginTop: 2 }}>{errors.isBlocked}</p>
                                        )}
                                    </FormControl>
                                    <TextField
                                        multiline
                                        minRows={3}
                                        name="description"
                                        label="Description"
                                        value={values.description}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={touched.description && errors.description}
                                        helperText={touched.description && errors.description}
                                    />
                                    <TextField
                                        type='file'
                                        name="picture"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        label="Image"
                                        onChange={(e) => setFieldValue("picture", e.target.files[0])}
                                        onBlur={handleBlur}
                                        InputProps={{
                                            inputProps: {
                                                accept: 'image/*',
                                            },
                                        }}
                                        error={touched.picture && errors.picture}
                                        helperText={touched.picture && errors.picture}
                                    />

                                    <Button variant="contained" type="submit">
                                        Create
                                    </Button>
                                </Stack>
                            </Form>
                        </FormikProvider>
                    </Box>
                </Modal>

                <TestimonialUpdateModal open={editOpen} handleClose={handleEditClose} currentTestimonial={testimonial} id={id} />
            </Page>

            <Popover
                open={Boolean(openMenu)}
                anchorEl={openMenu}
                onClose={handleCloseMenu}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 1,
                        width: 140,
                        '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            >
                <MenuItem onClick={handleEditOpen}>
                    <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                    Edit
                </MenuItem>

                <MenuItem sx={{ color: 'error.main' }} onClick={handleDelete}>
                    <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                    Delete
                </MenuItem>
            </Popover>
        </>
    );
};




export const TestimonialUpdateModal = ({ id, open, handleClose, currentTestimonial }) => {

    const formik = useFormik({
        initialValues: {
            fullname: currentTestimonial.fullname,
            isBlocked: currentTestimonial.isBlocked,
            description: currentTestimonial.description,
            picture: ""
        },
        enableReinitialize: true,
        validationSchema: TestimonialUpdateSchema,
        onSubmit: async (values, { resetForm }) => {
            const formData = new FormData();
            formData.append("id", id)
            Object.keys(values).forEach((key) => {
                formData.append(key, values[key]);
            });
            try {
                const res = await axios.put(`${url}/testimonial/update`, formData);
                if (!res.data.isError) {
                    toast.success(res.data.message)
                    handleClose();
                    resetForm();
                } else {
                    toast.error(res.data.message)
                    console.log(res.data.data);

                }
            } catch (e) {
                toast.error(e.response.data.message)
                console.log(e);
            }
        }
    });

    const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue } = formik;

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Update Testimonial
                        </Typography>
                        <Stack gap={3} mt={2}>
                            <TextField
                                name="fullname"
                                label="Fullname"
                                value={values.fullname}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.fullname && errors.fullname}
                                helperText={touched.fullname && errors.fullname}
                                InputLabelProps={{ shrink: true }}
                            />
                            <FormControl fullWidth margin="none" helperText={touched.isBlocked && errors.isBlocked}>
                                <InputLabel id="demo-simple-select-label" sx={{ color: `${touched.isBlocked && errors.isBlocked && "red"}` }}>
                                    Status
                                </InputLabel>
                                <Select
                                    name="isBlocked"
                                    error={touched.isBlocked && errors.isBlocked}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Status"
                                    value={values.isBlocked}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                >
                                    <MenuItem value={false}>Active</MenuItem>
                                    <MenuItem value={true}>Blocked</MenuItem>
                                </Select>
                                {touched.isBlocked && errors.isBlocked && (
                                    <p style={{ color: "red", fontWeight: 400, fontSize: "0.75em", marginTop: 2 }}>{errors.isBlocked}</p>
                                )}
                            </FormControl>
                            <TextField
                                multiline
                                minRows={3}
                                name="description"
                                label="Description"
                                value={values.description}
                                InputLabelProps={{ shrink: true }}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.description && errors.description}
                                helperText={touched.description && errors.description}
                            />
                            <TextField
                                type='file'
                                name="picture"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                label="Image"
                                onChange={(e) => setFieldValue("picture", e.target.files[0])}
                                onBlur={handleBlur}
                                InputProps={{
                                    inputProps: {
                                        accept: 'image/*',
                                    },
                                }}
                                error={touched.picture && errors.picture}
                                helperText={touched.picture && errors.picture}
                            />

                            <Button variant="contained" type="submit">
                                Update
                            </Button>
                        </Stack>
                    </Form>
                </FormikProvider>
            </Box>
        </Modal>
    )
}
