import axios from 'axios';
import { sentenceCase } from 'change-case';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { useSelector } from 'react-redux';
import {
    Avatar,
    Button,
    Card,
    Container,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Popover,
    Select,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
// components
import Iconify from '../components/Iconify';
import Label from '../components/Label';
// sections
import { TicketsListHead, TicketsListToolbar } from '../sections/@dashboard/tickets';
// mock

// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'slno', label: 'Sl.No', alignRight: false },
    { id: 'date', label: 'Date', alignRight: false },
    { id: 'user', label: 'User', alignRight: false },
    { id: 'subject', label: 'Subject', alignRight: false },
    { id: 'category', label: 'Category', alignRight: false },
    { id: 'courseId', label: 'Course ID', alignRight: false },
    { id: 'status', label: 'Status', alignRight: false },
    { id: 'blockStatus', label: 'Block Status', alignRight: false },
    { id: 'action', label: 'Action', alignRight: false },
];

// ----------------------------------------------------------------------

const url = process.env.REACT_APP_API_URL;

function SupportPage() {

    const [page, setPage] = useState(0);

    const [rowsPerPage, setRowsPerPage] = useState(5);

    const [title, setTitle] = useState("");

    const [maxRecords, setMaxRecords] = useState(0);


    const [tickets, setTickets] = useState([]);


    const [statusFilter, setStatusFilter] = useState("");

    const [categoryFilter, setCategoryFilter] = useState("");

    const [courseIdFilter, setCourseIdFilter] = useState("");

    const [filters, setFilters] = useState({
        category: "",
        status: "",
        courseId: "",
    });

    const [anchorEl, setAnchorEl] = useState(null);


    const { currentAdmin } = useSelector((state) => state.admin)

    const navigate = useNavigate();

    const fetchTickets = async () => {
        try {
            const res = await axios.get(`${url}/support-ticket/instructor/all?page=${page}&size=${rowsPerPage}&subject=${title}&category=${filters.category}&status=${filters.status}&courseId=${filters.courseId}`);
            if (!res.data.isError) {
                setTickets(res.data.data.data)
                setMaxRecords(res.data.data.maxRecords)
            }
        } catch (e) {
            console.log(e)
        }
    };


    useEffect(() => {
        fetchTickets();
    }, [page, rowsPerPage, title, filters])

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleFilterSubmit = () => {
        setFilters({
            status: statusFilter,
            category: categoryFilter,
            courseId: courseIdFilter
        })
        handleClose();
    };


    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;



    const [isCopiedArray, setIsCopiedArray] = useState([]);

    const handleCopyToClipboard = (input, rowIndex) => {
        navigator.clipboard.writeText(input).then(() => {
            const updatedIsCopiedArray = [...isCopiedArray];
            updatedIsCopiedArray[rowIndex] = true;
            setIsCopiedArray(updatedIsCopiedArray);
        });
    }

    return (
        <>
            <Container maxWidth={"xl"}>

                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
                    <Typography variant="h5" gutterBottom>
                        All Tickets
                    </Typography>
                </Stack>

                <Card>
                    <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} px={3} width={"100%"}>
                        <TicketsListToolbar filterName={title} onFilterName={(e) => setTitle(e.target.value)} />
                        <Tooltip title="Filter list">
                            <IconButton onClick={handleClick}>
                                <Iconify icon="ic:round-filter-list" />
                            </IconButton>
                        </Tooltip>
                    </Stack>

                    <TableContainer sx={{ minWidth: 800 }}>
                        <Table>
                            <TicketsListHead
                                headLabel={TABLE_HEAD}
                                rowCount={tickets.length}
                            />
                            <TableBody>
                                {
                                    tickets.map((item, index) => (
                                        <TableRow hover key={item?._id} tabIndex={-1} role="checkbox">
                                            <TableCell align="left"><Typography variant="subtitle2" noWrap>{page * rowsPerPage + index + 1}</Typography></TableCell>
                                            <TableCell align="left"><Typography variant="subtitle2" noWrap>{moment(item?.createdAt).format("ll")}</Typography></TableCell>
                                            <TableCell align="left"><Button onClick={() => navigate(`/dashboard/students/view?id=${item.userId}`)} sx={{ gap: "10px" }}><Avatar src={item.user.profilePic} alt={item.user.fullname} />{item.user.fullname}</Button></TableCell>
                                            <TableCell align="left"><Typography variant="subtitle2" noWrap>{item?.subject}</Typography></TableCell>
                                            <TableCell align="left"><Typography variant="subtitle2" noWrap>                                                <Label variant="outlined" >
                                                {item?.category}
                                            </Label></Typography></TableCell>
                                            <TableCell align="left"><Typography variant="subtitle2" noWrap>
                                                {
                                                    item?.courseId ?
                                                        <Button
                                                            onClick={() => {
                                                                handleCopyToClipboard(item?.courseId, index);
                                                            }}
                                                            variant='outlined'
                                                            startIcon={<Iconify icon={"lucide:clipboard-copy"} />}
                                                            disabled={isCopiedArray[index]}
                                                        >
                                                            {isCopiedArray[index] ? 'Copied' : 'Copy ID'}
                                                        </Button> :
                                                        <Typography variant="subtitle2" noWrap>N/A</Typography>
                                                }
                                            </Typography>
                                            </TableCell>

                                            <TableCell align="left">
                                                <Label variant="outlined" color={(item?.status !== "OPEN" && 'error') || 'success'}>
                                                    {item?.status}
                                                </Label>

                                            </TableCell>
                                            <TableCell align="left">
                                                <Label variant="ghost" color={(item?.isBlocked && 'error') || 'success'}>
                                                    {sentenceCase(item?.isBlocked ? "blocked" : "active")}
                                                </Label>
                                            </TableCell>
                                            <TableCell align='left'>
                                                <IconButton onClick={() => navigate(`/dashboard/support/view?id=${item._id}`)}>
                                                    <Iconify icon={"carbon:view-filled"} />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>

                        </Table>
                    </TableContainer>

                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={maxRecords}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={(e, newPage) => setPage(newPage)}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>

                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <Stack gap={2} p={2}>

                        <Typography variant='h6'>Filters</Typography>
                        <Grid container width={{ xs: "320px", sm: "500px" }} rowGap={3} columnSpacing={2} p={3}>
                            <Grid item xs={12} md={12}>
                                <TextField onChange={(e) => setCourseIdFilter(e.target.value)} size='small' value={courseIdFilter} fullWidth label={"Course Id"} InputLabelProps={{
                                    shrink: true,
                                    style: {
                                        fontSize: "13px !important"
                                    }
                                }}
                                    sx={{ fontSize: "13px" }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth size='small'>
                                    <InputLabel sx={{ fontSize: "13px !important" }} id="demo-simple-select-label">Status</InputLabel>
                                    <Select
                                        size='small'
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={statusFilter}
                                        label="Status"
                                        inputProps={{ style: { fontSize: "12px" } }}
                                        MenuProps={{ style: { fontSize: "12px !important" } }}
                                        onChange={(e) => setStatusFilter(e.target.value)}
                                        sx={{ fontSize: "12px !important" }}
                                    >
                                        <MenuItem sx={{ fontSize: "13px !important" }} value={""}>All</MenuItem>
                                        <MenuItem sx={{ fontSize: "13px !important" }} value={"OPEN"}>OPEN</MenuItem>
                                        <MenuItem sx={{ fontSize: "13px !important" }} value={"CLOSED"}>CLOSED</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth size='small'>
                                    <InputLabel sx={{ fontSize: "13px !important" }} id="demo-simple-select-label">Category</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={categoryFilter}
                                        label="Category"
                                        onChange={(e) => setCategoryFilter(e.target.value)}
                                        sx={{ fontSize: "12px !important" }}
                                    >
                                        <MenuItem sx={{ fontSize: "13px !important" }} value={""}>All</MenuItem>
                                        <MenuItem sx={{ fontSize: "13px !important" }} value={"ACCOUNT"}>Account</MenuItem>
                                        <MenuItem sx={{ fontSize: "13px !important" }} value={"COURSE"}>Course</MenuItem>
                                        <MenuItem sx={{ fontSize: "13px !important" }} value={"OTHERS"}>Others</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Button fullWidth variant='contained' onClick={handleFilterSubmit}>Submit</Button>
                            </Grid>
                        </Grid>
                    </Stack>
                </Popover>
            </Container>
        </>
    )
}

export default SupportPage