
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    title: 'students',
    path: '/dashboard/students',
    icon: getIcon('mdi:account-student'),
  },
  {
    title: 'courses',
    path: '/dashboard/courses',
    icon: getIcon('raphael:books'),
  },
  {
    title: 'submissions',
    path: '/dashboard/submissions',
    icon: getIcon('ic:round-assignment'),
  },
  {
    title: 'orders',
    path: '/dashboard/orders',
    icon: getIcon('material-symbols:order-approve-outline-rounded'),
  },
  {
    title: 'certificates',
    path: '/dashboard/certificates',
    icon: getIcon('mdi:certificate-outline'),
  },
  {
    title: 'support',
    path: '/dashboard/support',
    icon: getIcon('ri:customer-service-2-fill'),
  },

  // {
  //   title: 'settings',
  //   icon: getIcon('ion:settings'),
  //   children: [
  //     {
  //       title: 'categories',
  //       path: '/dashboard/categories',
  //       icon: getIcon('bx:category-alt'),
  //     },
  //   ],
  // },

  {
    title: 'webinars',
    path: '/dashboard/webinars',
    icon: getIcon('mdi:web'),
  },
];

export default navConfig;