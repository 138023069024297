import { Grid } from '@mui/material'
import axios from 'axios'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from "react-router-dom"
import Details from '../components/AdminProfile/Details'
import Profile from '../components/AdminProfile/Profile'
import Page from '../components/Page'

import BackButton from '../components/BackButton'
import { fetchAdministratorFailure, fetchAdministratorStart, fetchAdministratorSuccess } from "../redux/administratorSlice"

const url = process.env.REACT_APP_API_URL;

function AdminView() {

    const dispatch = useDispatch();
    const reload = useSelector((state) => state.administrator.reload)

    const [searchParams] = useSearchParams();
    const id = searchParams.get("id")

    const fetchInstructor = async () => {
        try {
            dispatch(fetchAdministratorStart())
            const res = await axios.get(`${url}/admin/get?id=${id}`);
            if (!res.data.isError) {
                dispatch(fetchAdministratorSuccess(res.data.data));
            } else {
                dispatch(fetchAdministratorFailure())
            }
        } catch (e) {
            dispatch(fetchAdministratorFailure())
            console.log(e)
        }
    };

    useEffect(() => {
        fetchInstructor();
    }, [reload]);
    return (
        <>
            <Page title="Students" >
                <BackButton link={-1} />
                <Grid container px={8} columnSpacing={3}>
                    <Grid item xs={12} md={4} align="center" >
                        <Profile />
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Details />
                    </Grid>
                </Grid>
            </Page>
        </>
    )
}

export default AdminView