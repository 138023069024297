import axios from 'axios';
import { capitalCase, sentenceCase } from 'change-case';
import { Form, FormikProvider, useFormik } from 'formik';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from "yup";
// material
import { Filter, FilterAlt } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  Card,
  Collapse,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Popover,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { ScaleLoader } from 'react-spinners';
// components
import Iconify from '../components/Iconify';
import Label from '../components/Label';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import { StudentListHead, StudentListToolbar } from '../sections/@dashboard/students';
// mock

const url = process.env.REACT_APP_API_URL;
// ----------------------------------------------------------------------

const StudentSchema = Yup.object().shape({
  fullname: Yup.string().required('Fullname is required'),
  email: Yup.string().email('Invalid email address').required('Email is required'),
  status: Yup.string().required('Status is required'),
  password: Yup.string().min(8).required("password is requred")
});


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius: '8px',
};



// ----------------------------------------------------------------------

export default function Students() {

  const [page, setPage] = useState(0);


  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [maxRecords, setMaxRecords] = useState(0);

  const [students, setStudents] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [isCopiedArray, setIsCopiedArray] = useState([]);

  const [open, setOpen] = useState(false);

  const [idFilter, setIdFilter] = useState("");
  const [isBlocked, setIsBlocked] = useState("");
  const [email, setEmail] = useState("");





  const [filters, setFilters] = useState({
    idFilter: "",
    emailFilter: "",
    isBlocked: "",
  });


  const navigate = useNavigate();
  const accType = localStorage.getItem("accType")
  const { currentAdmin } = useSelector((state) => state.admin)



  const TABLE_HEAD = [
    { id: 'slno', label: 'Sl.no', alignRight: false },
    { id: 'fullname', label: 'Fullname', alignRight: false },
    { id: 'id', label: 'Copy ID', alignRight: false },
    { id: 'email', label: 'Email', alignRight: false },
    { id: 'isBlocked', label: 'Status', alignRight: false },
    { id: 'lastLogin', label: 'Last login', alignRight: false },
    { id: '' },
  ];



  const [filterName, setFilterName] = useState('');
  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };



  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  const fetchInstructorStudents = async () => {
    try {
      setIsLoading(true)
      const res = await axios.get(`${url}/user/instructor/getall?instructorId=${currentAdmin._id}&page=${page}&size=${rowsPerPage}&fullname=${filterName}&email=${filters.emailFilter}&isBlocked=${filters.isBlocked}&id=${filters.idFilter}`);
      setStudents(res.data.data.records)
      setMaxRecords(res.data.data.maxRecords)
      setIsLoading(false);
    } catch (e) {
      console.log(e)
      setIsLoading(false);
    }
  }

  const formik = useFormik({
    initialValues: {
      fullname: "",
      email: "",
      status: "",
      password: "",
    },
    validationSchema: StudentSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        const res = await axios.post(`${url}/user/create`, {
          fullname: values.fullname,
          email: values.email,
          hash: values.password,
          isBlocked: values.status
        });
        if (!res.data.isError) {
          toast.success(res.data.message)
          setOpen(false)
          resetForm();
          fetchInstructorStudents();
        } else {
          toast.error(res.data.message)
          console.log(res.data.data);

        }
      } catch (e) {
        toast.error(e.response.data.message)
        console.log(e);
      }
    }
  });
  const { values, errors, touched, handleChange, handleBlur, handleSubmit } = formik;

  useEffect(() => {
    fetchInstructorStudents();

  }, [page, rowsPerPage, filterName, filters]);

  const handleRowsPerPageChange = (event) => {
    const newRowsPerPage = event.target.value;
    const currentPage = Math.floor((page * rowsPerPage) / newRowsPerPage);
    setRowsPerPage(newRowsPerPage);
    setPage(currentPage);
  };


  const handleCopyToClipboard = (input, rowIndex) => {
    navigator.clipboard.writeText(input).then(() => {
      const updatedIsCopiedArray = [...isCopiedArray];
      updatedIsCopiedArray[rowIndex] = true;
      setIsCopiedArray(updatedIsCopiedArray);
    });
  }


  const [openFilter, setOpenFilter] = useState(false);

  const handleFilterClick = (event) => {
    setOpenFilter(!openFilter);
  };



  const handleApplyFilter = () => {
    setFilters({
      emailFilter: email,
      isBlocked,
      idFilter,
    })
  }

  return (
    <>
      <Page title="Students">
        <Container maxWidth={"xl"}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Students
            </Typography>
          </Stack>

          <Card >
            <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
              <StudentListToolbar filterName={filterName} onFilterName={handleFilterByName} />
              <IconButton onClick={handleFilterClick} sx={{ mr: 3 }}><FilterAlt /></IconButton>
            </Stack>

            <Collapse in={openFilter}>
              <Grid container spacing={3} p={3}>
                <Grid item sm={12}>
                  <Typography variant='h5'>Filters</Typography>
                </Grid>
                <Grid item sm={4}>
                  <TextField fullWidth size='small' label="ID" onChange={(e) => setIdFilter(e.target.value)} />
                </Grid>

                <Grid item sm={4}>
                  <TextField fullWidth size='small' label="Email" onChange={(e) => setEmail(e.target.value)} />
                </Grid>
                <Grid item sm={4}>

                  <FormControl fullWidth>
                    {/* <InputLabel >Status</InputLabel> */}
                    <TextField
                      id="demo-simple-select"
                      value={isBlocked}
                      label="Status"
                      select
                      type=''
                      onChange={(e) => setIsBlocked(e.target.value)}
                      size='small'
                    >
                      <MenuItem value={""}>None</MenuItem>
                      <MenuItem value={false}>Active</MenuItem>
                      <MenuItem value={true}>Blocked</MenuItem>
                    </TextField>
                  </FormControl>
                </Grid>
                <Grid item sm={4}>
                  <Button variant='contained' onClick={handleApplyFilter} >Apply Filter</Button>
                </Grid>
              </Grid>
            </Collapse>


            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <StudentListHead
                    headLabel={TABLE_HEAD}
                    rowCount={students.length}
                  />
                  <TableBody>
                    {
                      isLoading ? (
                        <TableRow>
                          <TableCell colSpan={TABLE_HEAD.length}>
                            <Stack
                              alignItems="center"
                              justifyContent="center"
                              minHeight="200px"
                            >
                              <ScaleLoader color="black" loading={isLoading} />
                            </Stack>
                          </TableCell>
                        </TableRow>

                      ) : (

                        students?.map((row, index) => {
                          return (
                            <TableRow
                              hover
                              key={row._id}
                              tabIndex={-1}
                            >
                              <TableCell padding="normal">
                                {page * rowsPerPage + index + 1}
                              </TableCell>
                              <TableCell component="th" scope="row" padding="none">
                                <Stack direction="row" alignItems="center" spacing={2}>
                                  <Avatar alt={row.fullname} src={row.profilePic} />
                                  <Typography variant="subtitle2" noWrap>
                                    {row.fullname && capitalCase(row.fullname)}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell component="th" scope="row" padding="none">
                                <Button
                                  onClick={() => {
                                    handleCopyToClipboard(row._id, index);
                                  }}
                                  variant='outlined'
                                  startIcon={<Iconify icon={"lucide:clipboard-copy"} />}
                                  disabled={isCopiedArray[index]}
                                >
                                  {isCopiedArray[index] ? 'Copied' : 'Copy ID'}
                                </Button>
                              </TableCell>
                              <TableCell align="left">{row.email}</TableCell>
                              {
                                accType !== "INSTRUCTOR" &&
                                <TableCell align="left">{row.purchasedCourseCount}</TableCell>
                              }
                              <TableCell align="left">
                                <Label variant="ghost" color={(row.isBlocked && 'error') || 'success'}>
                                  {sentenceCase(row.isBlocked ? "blocked" : "active")}
                                </Label>
                              </TableCell>
                              <TableCell align="left">{moment(row?.lastLogin).format("MMM Do YYYY")}</TableCell>

                              <TableCell align="center">
                                <Tooltip title="View">
                                  <IconButton onClick={() => navigate(`/dashboard/students/view?id=${row._id}`)}>
                                    <Iconify icon={"carbon:view-filled"} width={22} height={22} />
                                  </IconButton>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          );
                        })

                      )}
                  </TableBody>


                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[10, 20, 30, 100]}
              component="div"
              count={maxRecords}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={(e, newValue) => setPage(newValue)}
              onRowsPerPageChange={handleRowsPerPageChange}
              showFirstButton showLastButton
            />
          </Card>


        </Container>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  New Student
                </Typography>
                <Stack gap={2}>
                  <TextField
                    name="fullname"
                    variant="outlined"
                    label="Fullname"
                    value={values.fullname}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.fullname && errors.fullname}
                    helperText={touched.fullname && errors.fullname}
                  />
                  <TextField
                    name="email"
                    variant="outlined"
                    label="Email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.email && errors.email}
                    helperText={touched.email && errors.email}
                  />
                  <TextField
                    name="password"
                    variant="outlined"
                    label="Password"
                    type="password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.password && errors.password}
                    helperText={touched.password && errors.password}
                  />
                  <FormControl fullWidth margin="none" helperText={touched.status && errors.status}>
                    <InputLabel id="demo-simple-select-label" sx={{ color: `${touched.status && errors.status && "red"}` }}>
                      Status
                    </InputLabel>
                    <Select
                      name="status"
                      variant="outlined"
                      error={touched.status && errors.status}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Status"
                      value={values.status}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <MenuItem value={"false"}>Active</MenuItem>
                      <MenuItem value={"true"}>Blocked</MenuItem>
                    </Select>
                    {touched.status && errors.status && (
                      <p style={{ color: "red", fontWeight: 400, fontSize: "0.75em", marginTop: 2 }}>{errors.status}</p>
                    )}
                  </FormControl>


                  <Button variant="contained" type="submit">
                    Create
                  </Button>
                </Stack>
              </Form>
            </FormikProvider>
          </Box>
        </Modal>


      </Page >

    </>
  );
}
