import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import {
    Button,
    Card,
    IconButton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    Tooltip,
    Typography
} from '@mui/material';
// components
import Iconify from '../components/Iconify';
// sections
import { TicketsListHead, TicketsListToolbar } from '../sections/@dashboard/tickets';
// mock

// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'slno', label: 'Sl.No', alignRight: false },
    { id: 'date', label: 'Date', alignRight: false },
    { id: 'name', label: 'Name', alignRight: false },
    { id: 'email', label: 'Email', alignRight: false },
    { id: 'contactNumber', label: 'Contact Number', alignRight: false },
    { id: 'status', label: 'Status', alignRight: false },
    { id: 'action', label: 'View', alignRight: false },
];

// ----------------------------------------------------------------------

const url = process.env.REACT_APP_API_URL;

function ContactUs() {

    const [page, setPage] = useState(0);

    const [rowsPerPage, setRowsPerPage] = useState(5);

    const [title, setTitle] = useState("");

    const [maxRecords, setMaxRecords] = useState(0);

    const [contactUs, setContactUs] = useState([]);





    const navigate = useNavigate();

    const fetchPurchases = async () => {
        try {
            const res = await axios.get(`${url}/contact-us/admin/all?page=${page}&size=${rowsPerPage}&title=${title}`);
            if (!res.data.isError) {
                setContactUs(res.data.data.records)
                setMaxRecords(Math.ceil(res.data.data.maxRecords / rowsPerPage))
            }
        } catch (e) {
            console.log(e)
        }
    };


    useEffect(() => {
        fetchPurchases();
    }, [page, rowsPerPage, title])

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };


    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;


    const handleFilterSubmit = () => {
        // setFilters({ ...filters, startDate: startDate, endDate: endDate })
        handleClose();
    };

    const [isCopiedArray, setIsCopiedArray] = useState([]);

    const handleCopyToClipboard = (input, rowIndex) => {
        navigator.clipboard.writeText(input).then(() => {
            const updatedIsCopiedArray = [...isCopiedArray];
            updatedIsCopiedArray[rowIndex] = true;
            setIsCopiedArray(updatedIsCopiedArray);
        });
    }

    return (
        <>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
                <Typography variant="h5" gutterBottom>
                    All Queries
                </Typography>
            </Stack>

            <Card>
                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} px={3} width={"100%"}>
                    <TicketsListToolbar filterName={title} onFilterName={(e) => setTitle(e.target.value)} />
                    <Tooltip title="Filter list">
                        <IconButton onClick={handleClick}>
                            <Iconify icon="ic:round-filter-list" />
                        </IconButton>
                    </Tooltip>
                </Stack>

                <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                        <TicketsListHead
                            headLabel={TABLE_HEAD}
                            rowCount={contactUs?.length}
                        />
                        <TableBody>
                            {
                                contactUs?.map((item, index) => (
                                    <TableRow hover key={item?._id} tabIndex={-1} role="checkbox">
                                        <TableCell align="left"><Typography variant="subtitle2" noWrap>{page * rowsPerPage + index + 1}</Typography></TableCell>
                                        <TableCell align="left"><Typography variant="subtitle2" noWrap>{moment(item?.createdAt).format("ll")}</Typography></TableCell>
                                        <TableCell align="left"><Typography variant="subtitle2" noWrap>{item?.name}</Typography></TableCell>
                                        <TableCell align="left"><Typography variant="subtitle2" noWrap>{item?.email}</Typography></TableCell>
                                        <TableCell align="left"><Typography variant="subtitle2" noWrap>{item?.contactNumber}</Typography></TableCell>
                                        <TableCell align="left">
                                            <Button variant='outlined'  >
                                                {item?.status}
                                            </Button>
                                        </TableCell>
                                        <TableCell align='left'>
                                            <IconButton onClick={() => navigate(`/dashboard/contact-us/view?id=${item._id}`)}>
                                                <Iconify icon={"carbon:view-filled"} />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>

                    </Table>
                </TableContainer>

                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={maxRecords}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={(e, newPage) => setPage(newPage)}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Card>
            {/* <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Stack gap={2} p={2}>

                    <Typography variant='h6'>Filters</Typography>
                    <Grid container width={"500px"} rowGap={3} columnSpacing={2} p={3}>
                        <Grid item xs={12} md={6}>
                            <TextField onChange={(e) => setStartDate(e.target.value)} size='small' fullWidth label={"Start Date"} type='date' InputLabelProps={{
                                shrink: true,
                            }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField onChange={(e) => setEndDate(e.target.value)} size='small' fullWidth label={"End Date"} type='date' InputLabelProps={{
                                shrink: true,
                            }}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Button fullWidth variant='contained' onClick={handleFilterSubmit}>Submit</Button>
                        </Grid>
                    </Grid>
                </Stack>
            </Popover> */}
        </>
    )
}

export default ContactUs