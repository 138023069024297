import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// material
import {
    Box,
    Button,
    Container,
    Stack,
    Tab,
    Tabs,
    Typography
} from '@mui/material';
// components
import Iconify from '../components/Iconify';
import Page from '../components/Page';
import { ActiveCourses, AllCourses, ArchivedCourses, BlockedCourses, FinalizedCourses, NotFinalizedCourses, } from '../components/courses';

// ----------------------------------------------------------------------

export default function Courses() {

    const navigate = useNavigate();

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    function CustomTabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }


    return (
        <>
            <Page title="Courses">
                <Container maxWidth={"xl"}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
                        <Typography variant="h4" gutterBottom>
                            Courses
                        </Typography>
                        <Button variant="contained" onClick={() => navigate("/dashboard/courses/add-course")} startIcon={<Iconify icon="eva:plus-fill" />}>
                            Add Course
                        </Button>
                    </Stack>

                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab label="All Courses" {...a11yProps(0)} />
                                <Tab label="Finalized" {...a11yProps(1)} />
                                <Tab label="Not Finalized" {...a11yProps(2)} />
                                <Tab label="Active" {...a11yProps(3)} />
                                <Tab label="Blocked" {...a11yProps(4)} />
                                <Tab label="Archived" {...a11yProps(5)} />
                            </Tabs>
                        </Box>
                        <CustomTabPanel value={value} index={0}>
                            <AllCourses />
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={1}>
                            <FinalizedCourses />
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={2}>
                            <NotFinalizedCourses />
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={3}>
                            <ActiveCourses />
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={4}>
                            <BlockedCourses />
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={5}>
                            <ArchivedCourses />
                        </CustomTabPanel>
                    </Box>


                </Container>
            </Page>

        </>
    );
}
