import * as Yup from 'yup';
import axios from 'axios';
import { useState } from 'react';
import { Formik, useFormik, FormikProvider, Form } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux"
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Button } from '@mui/material';
import { toast } from 'react-toastify';
// components
import { loginStart, loginSuccess } from '../../../redux/adminSlice';
import Iconify from '../../../components/Iconify';
import { setAdminToken, setAdminType } from '../../../storeService';

const url = process.env.REACT_APP_API_URL;
// ----------------------------------------------------------------------


export default function InstructorLoginForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(false);

  const loginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string()
      .min(8, 'Password must be at least 8 characters')
      .required('Password is required'),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginSchema,
    onSubmit: async (values) => {
      try {
        dispatch(loginStart())
        const res = await axios.post(`${url}/auth/instructor/login`, {
          email: values.email,
          hash: values.password
        });
        if (!res.data.isError) {
          dispatch(loginSuccess(res.data.data))
          toast.success(res.data.message)
          window.location = "/dashboard/app"
          setAdminToken(res.data.data.token);
          setAdminType("INSTRUCTOR");
        }
        if (res.data.isError) {
          toast.error(res.data.message)
        }
      } catch (e) {
        toast.error(e.response.data.message)
        console.log(e)
      }
    },
  });


  const { values, errors, touched, handleChange, handleBlur, handleSubmit } = formik;

  return (
    <FormikProvider value={formik}>

      <Form autoComplete="off" onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField type="email"
            name="email"
            label="Email address"
            variant="outlined"
            error={touched.email && Boolean(errors.email)}
            helperText={touched.email && errors.email}
            fullWidth
            margin="normal"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
          />

          <TextField
            name="password"
            label="Password"
            variant="outlined"
            error={touched.password && Boolean(errors.password)}
            helperText={touched.password && errors.password}
            fullWidth
            margin="normal"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <Link variant="subtitle2" underline="hover">
            {/* Forgot password? */}
          </Link>
        </Stack>

        <Button fullWidth size="large" type="submit" variant="contained" >
          Instructor Login
        </Button>
      </Form>
    </FormikProvider>
  );
}
