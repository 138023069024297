import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useSearchParams } from "react-router-dom"
import { Grid } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import Page from '../components/Page'
import Profile from '../components/InstructorProfile/Profile'
import Details from '../components/InstructorProfile/Details';
import { fetchInstructorFailure, fetchInstructorStart, fetchInstructorSuccess } from '../redux/instructorSlice'
import BackButton from '../components/BackButton'

const url = process.env.REACT_APP_API_URL;

function TeacherView() {

    const dispatch = useDispatch();
    const reload = useSelector((state) => state.instructor.reload)

    const [searchParams] = useSearchParams();
    const id = searchParams.get("id")

    const fetchInstructor = async () => {
        try {
            dispatch(fetchInstructorStart())
            const res = await axios.get(`${url}/instructor/admin/get?id=${id}`);
            if (!res.data.isError) {
                dispatch(fetchInstructorSuccess(res.data.data));
            } else {
                dispatch(fetchInstructorFailure())
            }
        } catch (e) {
            dispatch(fetchInstructorFailure())
            console.log(e)
        }
    };

    useEffect(() => {
        fetchInstructor();
    }, [reload]);

    return (
        <>
            <Page title="Instructors">
                <BackButton link={-1} />
                <Grid container px={8} columnSpacing={3}>
                    <Grid item xs={12} md={4} align="center" >
                        <Profile />
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Details />
                    </Grid>
                </Grid>
            </Page>
        </>
    )
}

export default TeacherView